import React from 'react'
import LandingPage from './LandingPage'


function Home() {
  return (
    <div>
      <LandingPage />
      
    </div>
  )
}

export default Home
